import { Link } from "gatsby"
import React from "react"
import logo from "../assets/images/img_final/logo.png"
import Mobile from "./nav-mobile"
import { AnchorLink } from "gatsby-plugin-anchor-links"

const Navbarnew = () => {
    return (
        <>
         <nav className="bg-white shadow py-3 fixed-top d-block d-sm-block d-md-block d-lg-none d-xl-none">
                <div className="container-fluid mx-0 px-0">
                    <div className="row mx-0">
                        <div className="col-2">
                        <Mobile/>
                        </div>
                        <div className="col-7"></div>
                        <div className="col-3">
                            <img className="img-fluid" src={logo} alt="logo" />
                        </div>
                    </div>
                </div>    
            </nav> 
            <nav className="navbar fixed-top shadow bg-white px-3 d-none d-sm-none d-md-none d-lg-block d-xl-block">
                <div className="col-xl-12 py-0">
                    <div className="d-flex">
                        <div className="flex-grow-1">
                            <img className="img-fluid" src={logo} alt="logo" />
                        </div>
                        <div className="px-2 text-center pt-3">
                            <ul className="m-ul menu">
                                <li><Link to="/">Inicio</Link></li>
                                <li><AnchorLink to="/#nosotros">Nosotros</AnchorLink></li>
                                <li><AnchorLink to="/#productos">Producto</AnchorLink></li>
                                <li><AnchorLink to="/#almacen">Almacenaje</AnchorLink></li>
                                <li><AnchorLink to="/#certificados">Certificados</AnchorLink></li>
                                <li><AnchorLink to="/#ubicaciones">Ubicaciones</AnchorLink></li>
                                <li><Link to="/contact">Contacto</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>    
            </nav>
            
        </>
    )
}


export default Navbarnew