import React, { useState } from 'react';
import { Link } from 'gatsby';
import menu from '../assets/images/img_final/menu.svg'
import { AnchorLink } from "gatsby-plugin-anchor-links"
const Mobile = () => {
  const [show, setShow] = useState(true);
  
  return (
    <>
        <button className="btn" type="button" onClick={() => {setShow(!show);}}>
           <img  src={menu} alt="logo" />
        </button>

      {show ? (
       <></>
      ) : (
        <div className="sidenav" >
            <div className="text-right pr-4 pt-3">
                <button className="btn" onClick={() => {setShow(!show);}}>
                    <img className="img-fluid" src={menu} alt="logo" />
                </button>
            </div>
            <div className="col-12 text-center">
                <ul>
                  <li><Link className="text-warning" to="/">Inicio</Link></li>
                  <li><AnchorLink className="text-warning" to="/#nosotros">Nosotros</AnchorLink></li>
                  <li><AnchorLink className="text-warning" to="/#productos">Producto</AnchorLink></li>
                  <li><AnchorLink className="text-warning" to="/#almacen">Almacenaje</AnchorLink></li>
                  <li><AnchorLink className="text-warning" to="/#certificados">Certificados</AnchorLink></li>
                  <li><AnchorLink className="text-warning" to="/#ubicaciones">Ubicaciones</AnchorLink></li>
                  <li><Link className="text-warning" to="/contact">Contacto</Link></li>
                </ul>
            </div>
        </div>
      )}
    </>
  );
};

export default Mobile